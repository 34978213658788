import styles from "./styles";
import { paymentOption } from "../../helpers/paymentOption";

const ProductInvoice = ({data}) => {

  const formatDateTime = (dateString, timeString) => {
    if (!dateString && !timeString) return '';
  
    let date = dateString ? new Date(dateString) : null;
    let time = timeString ? new Date(timeString) : null;
  
    if (date) {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
    }
  
    return time ? `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}` : '';
  };

  return (
    <>
      <div className="invoice">
        <div className="header">
          <table style={styles.width}>
            <tr>
              <td style={styles.textCenter}>
                <h1 className="heading" style={styles.heading}>Tress Lounge</h1>
              </td>
            </tr>
            <tr>
              <td className="borderb" style={{ ...styles.borderb, ...styles.paddingb }}>
                <table style={styles.width}>
                  <tr>
                    <td className="info" style={styles.info}>
                      {(data.created_at || data.time) && (
                        <p className="info" style={styles.info}>
                          Date: {formatDateTime(data.created_at, data.time)}
                        </p>
                      )}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        {data.get_org_info?.gst_no && data.get_org_info?.legal_name && (
          <div className="gst-details">
            <div>
              <span><strong>GST No:</strong> {data.get_org_info.gst_no}</span>
            </div>
            <div>
              <span><strong>Legal Name:</strong> {data.get_org_info.legal_name}</span>
            </div>
          </div>
        )}

        <div className="details">
          <p>Bill No: {data.bill_no}</p>
        </div>

        <div className="details">
          <p>Bill To:</p>
          <p>{data.get_customer_info?.name}</p>
          <p>{data.get_customer_info?.phone_number}</p>
        </div>

        <table className="invoice-table prod-list">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {data?.get_multiple_products.map((item, index) => (
              <tr key={index}>
                <td>{item?.get_product_name?.name || ''}</td>
                <td>{item.quantity}</td>
                <td>{item.price}</td>
                <td>{item.total}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            {data.product_multiple_payments.length > 0 ? (
              data.product_multiple_payments.map((payment, index) => (
                <tr key={index}>
                  <td colSpan="3" className="theadtd" style={styles.theadtd}>{paymentOption(payment.payment_mode)}</td>
                  <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>
                    {payment.price}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">Payment Mode</td>
                <td>{typeof paymentOption(data.payment_mode) === 'string' ? paymentOption(data.payment_mode) : 'Invalid Payment Method'}</td>
              </tr>
            )}
            <tr>
              <td colSpan="3">Subtotal</td>
              <td>{data.sub_total}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
}

export default ProductInvoice;
