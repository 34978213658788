import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { handleCustomer } from '../../services/customerServices';
import { showToastMessage } from '../../helpers/toastr';
import { validatePhone } from '../../helpers/validatePhone';
import { formatAmountSymbol } from '../../helpers/formatAmountSymbol';
import { discountValidate } from '../../helpers/discountValidate';
import { formatIntger } from '../../helpers/formatIntger';
import { handleEditMembership } from '../../services/membershipService';
import { selectOptions } from '../../helpers/paymentOption';
Modal.setAppElement('#root')




const EditMembershipModal = ({ modalIsOpen, closeModal, customStyles, type, setIsOpen ,fetchData, EditBilling}) => {

  const [btn, setBtn] = useState(false);

  const headerText = type === "add" ? 'Add Customer' : 'Edit Outstanding Balance';
 

  const [errors, setErrors] = useState({});
  const [isVisible, setIsVisible] = useState([false]);
  
  const [formData, setFormData] = useState({
    uuid:'',
    customerPaidAmount: '',
    id:'',
    paymentMode:"",
    outStandingAmount:'',
    type: type,
    multiplePaymentMethods: "",
  });

  const [fields, setFields] = useState([
      { selectValue: "", inputValue: "" },
  ]);

  const handleSelectChange = (e, index) => {
      const updatedFields = [...fields];
      updatedFields[index].selectValue = e.target.value;
      if(updatedFields[index].selectValue===""){
          updatedFields[index].inputValue=""
      }
      setFormData({...formData, multiplePaymentMethods:updatedFields})
      setFields(updatedFields);
      const newIsVisible = [...isVisible];
      newIsVisible[index] = true;
      setIsVisible(newIsVisible);
  };
  useEffect(() => {

    if (type === 'edit' && EditBilling) {
      setFormData({
        id: EditBilling.id,
        uuid:EditBilling.uuid,
        paymentMode:EditBilling.payment_mode,
        outStandingAmount:EditBilling.outStandingAmount == null ? '' : EditBilling.outStandingAmount,
        customerPaidAmount:'',
        type: type,
      });
    } else{
      setFormData({
        id: '',
        customerPaidAmount:'',
        outStandingAmount:'',
        type: 'edit',
        paymentMode:'',
      });
    }

    setErrors({});

  }, [modalIsOpen]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData?.customerPaidAmount.trim()) {
      newErrors.customerPaidAmount = "Customer paid is required";
      isValid = false;
    } else if (parseFloat(formData?.customerPaidAmount) === 0) {
      newErrors.customerPaidAmount = "Customer paid cannot be zero";
      isValid = false;
    }

    if(parseFloat(formData?.customerPaidAmount) > Math.abs(EditBilling?.out_standing)){
      newErrors.customerPaidAmount = `Customer paid cannot exceed ${Math.abs(EditBilling?.out_standing)}`;
      isValid = false;
    }

    if (!formData.paymentMode.trim()) {
      newErrors.paymentMode = "Payment mode is required";
      isValid = false;
    }
    

    setErrors(newErrors);
    return isValid;
  };

 

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const processedValue = type === 'number' ? parseFloat(value) : value;
  
    if (name === 'customerPaidAmount') {
      const newOutStandingAmount = Math.abs(EditBilling?.out_standing) - processedValue;
      setFormData((prevFormData) => ({
        ...prevFormData,
        outStandingAmount: newOutStandingAmount >= 0 ? newOutStandingAmount : 0,
        [name]: processedValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: processedValue,
      }));
    }
  
    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    };
  
  const handleMultiInputChange = (e, index) => {
      const updatedFields = [...fields];
      updatedFields[index].inputValue = e.target.value;
      if (updatedFields[index].selectValue !== "") {
        setFields(updatedFields);
      }
  };
  
  const saveBilling = async (type) => {

   
    if (validateForm()) {

      console.log(formData,'formData');

      // return false;

      setBtn(true);

        try {
            const result = await handleEditMembership(formData);

            console.log(result,'result');
    
            if (result.data) {
                showToastMessage('success', result.data.message)
                fetchData()
                closeModal();
                setBtn(false);
            }
    
          } catch (error) {
            setBtn(false);
            console.log(error);

            if(error.response.status === 404){
              const errors = error.response.data.message
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).forEach((key) => {
                  errors[key].forEach((message) => {
                    showToastMessage('error', message)
                  });
                });
              }
            }else{
              showToastMessage('error', error.response.data.message)
            }

          }

    }
  };

  return <>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      setIsOpen={setIsOpen}
      shouldCloseOnOverlayClick={false}
      contentLabel="Example Modal"
      className="custom-modal round-15 bg-white"
    >   
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
          <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}><i class="mdi mdi-close menu-icon"></i></button>
        </div>

      <form className="mx-auto mt-4 form" style={{ maxWidth: '100%' }}>
        <div className="row">
        <label htmlFor="name" className="form-label">Outstanding Balance:{formatAmountSymbol(EditBilling?.out_standing)}</label>
          <div className="col-md-6 col-12">
            <div className="form-group">
               <label htmlFor="name" className="form-label">Customer Pay:</label>
                 <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="customerPaidAmount"
                  value={formData.customerPaidAmount}
                  onKeyDown={(e) => formatIntger(e)}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="Enter Customer Pay"
                />
                {errors.customerPaidAmount && (
                  <div className="text-danger">{errors.customerPaidAmount}</div>
                )}
            </div>
          </div>
          {/* paymentMode */}

          <div className="col-md-6 col-12">
            <div className="form-group">
                <label htmlFor="name" className="form-label">Payment Mode:</label>
                {fields.map((field, index) => (
                  <>
                    <select 
                      className="payment_mode form-select form-control" 
                      name="paymentMode"
                      value={field.selectValue}
                      onChange={(e) => {
                          handleInputChange(e);
                          handleSelectChange(e, index);
                      }}
                    >
                    <option value="">Select Payment Mode</option>
                  {selectOptions('deleteOption')}
                  </select>
                  {isVisible[index] === true && field.selectValue!=="" && (
                    <input
                        min="1"
                        type="number"
                        className='form-control'
                        defaultValue={field.inputValue}
                        onChange={(e) =>
                        handleMultiInputChange(e, index)
                        }
                    />
                )}
                  </>
                ))}
              {errors.paymentMode && (
                  <div className="text-danger">{errors.paymentMode}</div>
              )}
              <button
                  type="button"
                  onClick={() =>{
                      setFields([
                      ...fields,
                      { selectValue: "", inputValue: "" },
                      ])
                    }
                  }
                  id="btn_add_row"
                  className="button button-primary button-icon-left"
              >
                  <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>

          
         
            <div className="col-md-12 col-12 text-end">
                <button type="button" disabled={btn} className="button button-primary button-icon-left" onClick={() => saveBilling(type)}>Submit</button>
            </div>
        </div>
      </form>
    </Modal>
  </>
}

export default EditMembershipModal;
