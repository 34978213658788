import React, { useEffect, useState } from 'react';
import Select from 'react-select';


export const MemberShipListing = ({ index, packages, staffData, removeRow, handlePackage, value , handleFieldChange , errors}) => {
    const [isCoStaffFocused, setIsCoStaffFocused] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    // Function to handle staff change and ensure they are not in the co-staff list
    const handleStaffChange = (newStaffId) => {
        let updatedCoStaff = [...(value.coStaffId || [])]; // Ensure coStaffId is an array

        // Remove the selected staff from co-staff if it exists
        if (updatedCoStaff.includes(newStaffId)) {
            updatedCoStaff = updatedCoStaff.filter(coStaffId => coStaffId !== newStaffId);
        }

        // Update both staff and co-staff
        handleFieldChange('staffId', newStaffId, index);
        handleFieldChange('coStaffId', updatedCoStaff, index);
    };

    const handleCoStaffChange = (selectedOptions) => {
        if (!value.staffId) {
            // If no staff is selected, show validation and do not allow selection
            setShowValidation(true);
            return;
        }

        // If staff is selected, allow changing co-staff
        handleFieldChange('coStaffId', selectedOptions.map(option => option.value), index);
        setShowValidation(false); // Hide validation if staff is selected
    };

    return (
        <div className="col-md-12" id={index} key={index}>
            <div className="added-card">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-12">
                        <div className="form-group">
                            <label>Package Name</label>
                            <Select
                                name="packageId"
                                options={packages}
                                placeholder={'Select Service'}
                                isSearchable={true}
                                classNamePrefix="form"
                                value={packages.find(option => option.value === value.packageId)}
                                onChange={(option) => {
                                    handlePackage(option, index);
                                    handleFieldChange('packageId', option.value, index)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-12">
                        <div className="form-group">
                            <label>Staff</label>
                            <Select
                                name="staffId"
                                value={staffData.find(option => option.value === value.staffId)}
                                isSearchable={true}
                                classNamePrefix="form"
                                options={staffData}
                                placeholder={'Select Staff'}
                                onChange={option => handleStaffChange(option.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-12">
                        <div className="form-group">
                            <label>Co-Staff</label>
                            <Select
                                value={
                                    staffData.filter(
                                        option =>
                                            (value?.coStaffId ?? []).includes(option.value)
                                    )
                                }
                                name="coStaffId"
                                isSearchable={true}
                                options={staffData.filter(option => option.value !== value.staffId)}
                                isMulti={true}
                                classNamePrefix="form"
                                placeholder={'Select Co-Staff'}
                                onChange={handleCoStaffChange}
                                onFocus={() => setIsCoStaffFocused(true)}
                            />
                            {isCoStaffFocused && !value.staffId && (
                                <small className="text-danger">Please select a staff member first.</small>
                            )}
                        </div>
                    </div>           
                    <div className="col-lg-2 col-md-4">
                        <div className="form-group">
                            <label>Customer Get</label>
                            <div className="percentage  margintop-10">
                                <input
                                    name="customerGet"
                                    type="number"
                                    disabled
                                    value={value.customerGet ? value.customerGet : 0}
                                    className="service_total form-control"
                                />
                                {/* <span className="service_total_txt">{value.customerGet ? value.customerGet : 0}</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                        <div className="form-group">
                            <label>Customer Pay</label>
                            <div className="percentage  margintop-10">
                                <input
                                    name="customerPay"
                                    type="number"
                                    readOnly
                                    value={value.customerPay ? value.customerPay : 0}
                                    className="service_total form-control"
                                />
                                {/* <span className="service_total_txt">{value.customerPay ? value.customerPay : 0}</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-delete">
                        <button type="button" className="button button-primary button-icon" onClick={() => removeRow(index)}>
                            <i class="mdi mdi-delete-empty menu-icon bodytext-24"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
