import React, { useEffect, useState } from "react";
import { Listing } from "../../../components/appointments/Listing";
import {
  handleBilling,
  handleCustomerSearch,
} from "../../../services/billingService";
import { showToastMessage } from "../../../helpers/toastr";
import { handleEditCustomer } from "../../../services/customerServices";
import { CustomerDetail } from "../../../components/appointments/CustomerDetail";
import { TableListing } from "../../../components/appointments/TableListing";
import { handleStaffListing } from "../../../services/staffServices";
import {
  handleEditService,
  handleServiceListing,
} from "../../../services/service";
import { formatAmount } from "../../../helpers/formatAmount";
import { NavLink, useNavigate } from "react-router-dom";
import { roundOffAmount } from "../../../helpers/roundOffAmount";
import { discountValidate } from "../../../helpers/discountValidate";
import { validatePhone } from "../../../helpers/validatePhone";
import { getCurrentDate } from "../../../helpers/getCurrentDate";
import { getCurrentTime } from "../../../helpers/getCurrentTime";
import { selectOptions } from "../../../helpers/paymentOption";
import { useSelector } from "react-redux";
import checkPermission from "../../../helpers/checkPermission";
import { debounce } from "lodash";
import { formatAmountSymbol } from "../../../helpers/formatAmountSymbol";
import IframeModal from "../../../components/modal/IframeModal";
import BillingInvoice from "../../InvoicesPrint/BillingInvoice";
import wrapModalfn from "../../../components/modal/modal";
import ClientJobCard from "../../InvoicesPrint/ClientJobCard";

const Billing = ({ selectGlobalOrg }) => {
  const navigate = useNavigate();
  const [minval, setMinVal] = useState(null);
  const userData = useSelector((state) => state.userData);
  const [discountLimit, setDiscountLimit] = useState("50");
  const [showDiscount, setshowDiscount] = useState("none");
  const [showSpecialDiscount, setShowSpecialDiscount] = useState(false);
  const [typeBTN, setTypeBTN] = useState("savePrint");
  const [showDiv, setShowDiv] = useState("none");
  const [showGender, setShowGender] = useState("");
  const [showDetail, setShowDetail] = useState("none");
  const [searchdata, setSearchData] = useState([]);
  const [value, setValue] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [services, setServices] = useState([]);
  const [btn, setBtn] = useState(false);
  const [errors, setErrors] = useState({});
  const [showWallet, setShowWallet] = useState("none");
  const { customStyles, openModal, closeModal, setIsOpen, type, modalIsOpen } =
    wrapModalfn();
  const [isVisible, setIsVisible] = useState([false]);

  const hasAdminRole = userData?.role?.role === "admin" ? true : false;
  const hasCoOwnerRole = userData?.role?.role === "co-owner" ? true : false;
  const [nameInputFocus, setNameInputFocus] = useState(false);
  const [contactInputFocus, setContactInputFocus] = useState(false);

  const [activeTab, setActiveTab] = useState("wallet");
  const [numPages, setNumPages] = useState(1);

  const [pdfData, setPdfData] = useState([]);
  const [iframeModalIsOpen, iframeSetIsOpen] = useState(false);

  function openIframeModal(type) {
    iframeSetIsOpen(true);
  }

  function closeIframeModal() {
    iframeSetIsOpen(false);
  }

  const [formData, setFormData] = useState({
    // customer
    name: "",
    phone: "",
    email: "",
    gender: "male",
    orgId: "",
    wallet: 0,
    refId: "",
    type: "",
    date: getCurrentDate(),
    time: getCurrentTime(),

    // Service Billing
    billingRemark: "",
    subTotal: "",
    billingTax: "",
    discountSymbol: "%",
    discountedAmount: "",
    discount: "",
    paymentMode: "",
    multiplePaymentMethods: "",
    grandTotal: "",
    specialDiscount: "",
    walletStatus: false,
    walletAmount: "0.00",
    roundOffGrandTotal: 0,
    taxEnabled: false,

    // Multiple billing service
    services: [
      {
        serviceId: "",
        staffId: "",
        // coStaffId:'',
        coStaffId: [],
        // tax:'18',
        quantity: "1",
        price: "",
        total: "",
      },
    ],
  });

  const [fields, setFields] = useState([
    { selectValue: "", inputValue: "" },
  ]);

  const handleSelectChange = (e, index) => {
    const updatedFields = [...fields];
    updatedFields[index].selectValue = e.target.value;
    if(updatedFields[index].selectValue===""){
        updatedFields[index].inputValue=""
    }
    setFormData({...formData, multiplePaymentMethods:updatedFields})
    setFields(updatedFields);
    const newIsVisible = [...isVisible];
    newIsVisible[index] = true;
    setIsVisible(newIsVisible);
  };

  const handleMultiInputChange = (e, index) => {
    const updatedFields = [...fields];
    updatedFields[index].inputValue = e.target.value;
    if (updatedFields[index].selectValue !== "") {
      setFields(updatedFields);
    }

  };

  const addSelectBox = () => {
    setFields([...fields, { selectValue: "", inputValue: "" }]);
  };
  
  const isMultiPaymentValid = () => {
    return fields.every(field => (field.selectValue !== "" || field.selectValue.trim() !== "") );
  };

  const isMultiPaymentAmountValid = () => {
    return fields.every(field => {

      // Skip validation if payment mode is "pkg"
      if (field.selectValue == "pkg" ) {
        return true;
      }
      return field.inputValue.trim() !== "" && field.selectValue.trim() !== "";
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (!isMultiPaymentValid()) {
      newErrors.paymentMode = "Payment mode is required";
      isValid = false;
      setErrors(newErrors);
      return isValid;
    }
    if (!isMultiPaymentAmountValid()) {
      newErrors.paymentMode = "Amount is required.";
      isValid = false;
      setErrors(newErrors);
      return isValid;
    }
    // Check discount based on symbol
    const discount = parseFloat(formData.discount);
    const discountSymbol = formData.discountSymbol;
    const subtotal = parseFloat(formData.subTotal);
    const discountLimitAmount = subtotal * (discountLimit / 100);

    if (discountSymbol === "%") {
      if (discount > discountLimit) {
        newErrors.discount = `Discount cannot exceed ${discountLimit}%`;
        isValid = false;
      }
    } else {
      if (discount > discountLimitAmount) {
        if (
          formData.specialDiscount === "" ||
          isNaN(formData.specialDiscount) ||
          formData.specialDiscount <= discountLimitAmount ||
          formData.specialDiscount > subtotal
        ) {
          newErrors.specialDiscount = `Special discount must be a number greater than ${discountLimitAmount} and less than or equal to ${subtotal}`;
          isValid = false;
        }
      }
    }

    const phoneValue = formData.phone.trim();

    if (!phoneValue) {
      newErrors.phone = "Phone is required";
      isValid = false;
    } else if (phoneValue.length !== 10) {
      newErrors.phone = "Phone number should be 10 numbers";
      isValid = false;
    } else if (!phoneValue.match(/^[6-9]\d{9}$/)) {
      newErrors.phone = "Invalid phone number format";
      isValid = false;
    }

    setErrors(newErrors);

    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const processedValue = type === "number" ? parseFloat(value) : value;

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: processedValue,
      };

      if (
        name === "discount" ||
        name === "discountSymbol" ||
        name === "services"
      ) {
        let subtotal = 0;
        let totalTax = 0;

        if (updatedFormData.services && updatedFormData.services.length > 0) {
          subtotal = updatedFormData.services.reduce(
            (acc, service) => acc + service.price * service.quantity,
            0
          );
          totalTax = updatedFormData.taxEnabled
            ? subtotal * 0.18
            : (subtotal * 0.18) / 1.18;
          totalTax = parseFloat(totalTax.toFixed(4));
        }

        let grandTotal = subtotal + totalTax;
        const walletAmount = updatedFormData.wallet;
        const walletStatus = updatedFormData.walletStatus;

        if (walletStatus && grandTotal > 0) {
          grandTotal = Math.max(
            0,
            grandTotal - Math.min(walletAmount, grandTotal)
          );
        }

        const discount = parseFloat(updatedFormData.discount);
        const discountSymbol = updatedFormData.discountSymbol;

        // Update discount limit based on symbol and user role
        let updatedDiscountLimit = 0;
        if (hasAdminRole) {
          updatedDiscountLimit = subtotal; // Allow up to 100% of subtotal for admin
        } else {
          updatedDiscountLimit = subtotal / 2; // Limit to 50% of subtotal for non-admin
        }
        setDiscountLimit(updatedDiscountLimit); // Update discount limit

        let discountAmt = 0;
        if (discountSymbol === "%") {
          discountAmt = (discount / 100) * subtotal;
          setShowSpecialDiscount(discount > 50); // Show special discount if discount > 50%
        } else {
          discountAmt = discount;
          setShowSpecialDiscount(discount > subtotal / 2); // Show special discount if discount > 50% of subtotal
        }

        // Limit discount based on role
        if (!hasAdminRole) {
          discountAmt = Math.min(discountAmt, subtotal / 2); // Limit to 50% of subtotal for non-admin
        }

        const discountedAmount = parseFloat(discountAmt.toFixed(4));
        const roundOffGrandTotal = Math.round(grandTotal - discountedAmount);

        updatedFormData.subTotal = subtotal;
        updatedFormData.billingTax = totalTax;
        updatedFormData.discountedAmount = discountedAmount;
        updatedFormData.grandTotal = parseFloat(grandTotal.toFixed(4));
        updatedFormData.roundOffGrandTotal = roundOffGrandTotal;

        // Handle special discount for rupee symbol
        if (discountSymbol === "₹") {
          updatedFormData.specialDiscount = processedValue; // Update special discount for rupee symbol
        }
      }

      return updatedFormData;
    });

    if (name === "walletStatus" || name === "taxEnabled") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleKeyUp = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);
  };

  const searchCustomerNumber = async (value) => {
    const orgID = Array.from(
      document.getElementById("orgId").selectedOptions,
      (option) => option.value
    );

    try {
      const result = await handleCustomerSearch(value, orgID);

      if (result.data) {
        if (result.data?.data.length > 0) {
          setSearchData(result.data?.data);
          setShowDiv("block");
        } else {
          setShowDiv("none");
          setSearchData([]);
        }
      }
    } catch (error) {
      showToastMessage("error", error.response.data.message);
    }
  };

  useEffect(() => {
    const debouncedFetchData = debounce(searchCustomerNumber, 300);

    const fetchDataOnChange = () => {
      debouncedFetchData(value);
    };

    if (value) {
      fetchDataOnChange(value);
    } else {
      setShowDiv("none");
      setSearchData([]);
      setValue("");
    }

    return () => {
      debouncedFetchData.cancel();
    };
  }, [value, selectGlobalOrg]);

  const handleCustomerClick = async (id) => {
    try {
      const result = await handleEditCustomer(id);

      if (result.data) {
        setSelectedCustomer(result.data?.data);
        setShowDetail("block");
        setShowDiv("none");
        setShowGender("none");
      } else {
        setShowGender("");
        setShowDiv("none");
        setShowDetail("none");
        setSelectedCustomer([]);
      }
    } catch (error) {
      showToastMessage("error", error.response.data.message);
    }
  };

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.length != 0) {
      let wallet;
      let status;
      if (selectedCustomer.wallet > 0) {
        setShowWallet("");
        status = true;
      } else {
        setShowWallet("none");
        status = false;
      }

      setFormData({
        name: selectedCustomer.name,
        phone: selectedCustomer.phone_number,
        uuid: selectedCustomer.uuid,
        email: selectedCustomer.email,
        gender: selectedCustomer.gender,
        dob: selectedCustomer.dob,
        orgId: selectedCustomer.org_id,
        customerId: selectedCustomer.id,
        wallet: selectedCustomer.wallet,
        type: "add",
        date: getCurrentDate(),
        time: getCurrentTime(),
        refId: "",

        billingRemark: "",
        subTotal: "0.00",
        billingTax: "0.00",
        discountSymbol: "%",
        discount: "",
        discountedAmount: "0.00",
        paymentMode: "",
        grandTotal: "0.00",
        specialDiscount: "",
        walletStatus: status,
        walletAmount: 0,
        roundOffGrandTotal: 0,
        taxEnabled: false,

        // Multiple billing service
        services: [
          {
            serviceId: "",
            staffId: "",
            // coStaffId:'',
            coStaffId: [],
            // tax:'18',
            quantity: "1",
            price: "",
            total: "",
          },
        ],
      });
    } else {
      const newOrgId = document.getElementById("orgId").value;
      setFormData({
        // customer
        name: "",
        phone: "",
        email: "",
        gender: "male",
        orgId: newOrgId,
        refId: "",
        type: "add",
        wallet: 0,
        date: getCurrentDate(),
        time: getCurrentTime(),

        // Service Billing
        billingRemark: "",
        subTotal: "0.00",
        billingTax: "0.00",
        discountSymbol: "%",
        discount: "",
        discountedAmount: "0.00",
        paymentMode: "",
        walletStatus: false,
        specialDiscount: "",
        walletAmount: 0,
        grandTotal: "0.00",
        roundOffGrandTotal: 0,
        taxEnabled: false,

        // Multiple billing service

        services: [
          {
            serviceId: "",
            staffId: "",
            // coStaffId:'',
            coStaffId: [],
            // tax:'18',
            quantity: "1",
            price: "",
            total: "",
          },
        ],
      });
    }
  }, [selectedCustomer]);

  useEffect(() => {
    fetchStaff();
    fetchServices();
  }, [selectGlobalOrg]);

  //   useEffect for fetch staff members

  const fetchStaff = async () => {
    const initialOrgIds = Array.from(
      document.getElementById("orgId").selectedOptions,
      (option) => option.value
    );

    try {
      const result = await handleStaffListing(initialOrgIds, "employee", "0");

      if (result.data) {
        setStaffData(result.data?.data);
      }
    } catch (error) {
      showToastMessage("error", error.response.data.message);
    }
  };

  const fetchServices = async () => {
    const initialOrgIds = Array.from(
      document.getElementById("orgId").selectedOptions,
      (option) => option.value
    );
    const status = 0;

    try {
      const result = await handleServiceListing(initialOrgIds, status);
      if (result?.data) {
        setServices(result?.data.data);
      }
    } catch (error) {
      showToastMessage("error", error.response.data.message);
    }
  };

  const addRow = () => {
    setFormData({
      ...formData,
      services: [
        ...formData.services,
        {
          serviceId: "",
          staffId: "",
          coStaffId: [],
          quantity: "",
          price: "",
          total: "",
        },
      ],
    });
  };

  const removeRow = (index) => {
    setFormData((prevState) => {
      if (prevState.services.length <= 1) {
        // Show toast message indicating that at least one service is required
        showToastMessage("error", "At least one service is required");
        return prevState; // Don't modify state
      }

      const updatedServices = [...prevState.services];
      updatedServices.splice(index, 1);

      let subtotal = 0;
      let totalTax = 0;
      let discountAmt = 0;
      const taxEnabled = formData.taxEnabled;

      updatedServices.forEach((service) => {
        subtotal += service.price * service.quantity;
        if (taxEnabled == true) {
          totalTax += (service.price * service.quantity * 18) / 100;
        } else {
          totalTax += (service.price * service.quantity * 18) / 118;
        }
      });

      if (taxEnabled == false) {
        subtotal -= totalTax;
      }

      let grandTotal = subtotal + totalTax;

      const walletAmount = formData.wallet;
      const walletStatus = formData.walletStatus;
      let amountToDeduct;

      if (walletStatus == true && grandTotal != 0) {
        amountToDeduct = Math.min(walletAmount, grandTotal);
        grandTotal -= amountToDeduct;
      }

      // Apply discount if available
      let discount = prevState && prevState.discount ? prevState.discount : 0;
      const discountSymbol =
        prevState && prevState.discountSymbol ? prevState.discountSymbol : "%";

      if (discountSymbol == "%") {
        discountAmt = Math.min(subtotal * (discount / 100), subtotal);
      } else {
        discountAmt = Math.min(discount, subtotal);
      }

      grandTotal -= discountAmt;

      return {
        ...prevState,
        walletAmount: amountToDeduct,
        services: updatedServices,
        roundOffGrandTotal: roundOffAmount(grandTotal),
        subTotal: formatAmount(subtotal),
        billingTax: formatAmount(totalTax),
        grandTotal: formatAmount(grandTotal),
        discount: discount || "0",
        discountedAmount: formatAmount(discountAmt),
      };
    });
  };

  const calculateTotalForRow = (quantity, price, tax) => {
    const totalPrice = parseFloat(quantity) * parseFloat(price);
    // const totalTax = totalPrice * (parseFloat(tax) / 100);
    return formatAmount(totalPrice);
  };

  const handleFieldChange = (fieldName, value, index) => {
    const walletAmount = formData.wallet;
    const walletStatus = formData.walletStatus;
    const taxEnabled = formData.taxEnabled;

    let amountToDeduct;

    if (fieldName === "discount") {
      if (parseFloat(value) > parseFloat(discountLimit)) {
        setshowDiscount("");
        if (walletAmount > 0) {
          setShowWallet("none");
        }
      } else {
        setshowDiscount("none");
        if (walletAmount > 0) {
          setShowWallet("");
        }
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        ["specialDiscount"]: "",
      }));
    }

    setFormData((prevState) => {
      const updatedServices = [...prevState.services];

      if (index !== undefined) {
        updatedServices[index] = {
          ...updatedServices[index],
          [fieldName]: value,
          total: calculateTotalForRow(
            fieldName === "quantity" ? value : updatedServices[index]?.quantity,
            fieldName === "price" ? value : updatedServices[index]?.price,
            "18"
          ),
        };
      }

      let subtotal = 0;
      let totalTax = 0;

      updatedServices.forEach((service) => {
        subtotal += service.price * service.quantity;
        if (fieldName === "taxEnabled") {
          if (taxEnabled == false) {
            totalTax += (service.price * service.quantity * 18) / 100;
          } else {
            totalTax += (service.price * service.quantity * 18) / 118;
          }
        } else {
          if (taxEnabled == true) {
            totalTax += (service.price * service.quantity * 18) / 100;
          } else {
            totalTax += (service.price * service.quantity * 18) / 118;
          }
        }
      });

      if (fieldName === "taxEnabled") {
        if (taxEnabled == true) {
          subtotal -= totalTax;
        }
      } else {
        if (taxEnabled == false) {
          subtotal -= totalTax;
        }
      }

      let grandTotal = subtotal + totalTax;
      let discount = prevState && prevState.discount ? prevState.discount : "";
      const discountSymbol =
        prevState && prevState.discountSymbol ? prevState.discountSymbol : "%";
      const discountVar =
        parseInt(discount) > parseInt(discountLimit) ? discountLimit : discount;
      let discountAmt = 0;
      // if (discountSymbol === '%') {
      //     discountAmt = Math.min(subtotal * (discountVar / 100), subtotal);
      // } else if (discountSymbol === '₹') {
      //     discountAmt = Math.min(discountVar, discountLimit);
      // }
      // else {
      //     discountAmt = Math.min(discountVar, subtotal);
      // }
      if (discountSymbol === "%") {
        discountAmt = Math.min(subtotal * (discountVar / 100), subtotal);
      } else {
        discountAmt = Math.min(discountVar, subtotal);
      }

      grandTotal -= discountAmt;

      let grand = roundOffAmount(grandTotal);

      if (discount <= discountLimit) {
        if (fieldName === "walletStatus") {
          if (walletStatus == false && grand !== 0) {
            amountToDeduct = Math.min(walletAmount, grand);
            grand -= amountToDeduct;
          }
        } else {
          if (walletStatus == true && grand !== 0) {
            amountToDeduct = Math.min(walletAmount, grand);
            grand -= amountToDeduct;
          }
        }
      }

      return {
        ...prevState,
        walletAmount: amountToDeduct,
        services: updatedServices,
        subTotal: formatAmount(subtotal),
        billingTax: formatAmount(totalTax),
        grandTotal: formatAmount(grandTotal),
        roundOffGrandTotal: grand,
        discount: discount,
        discountedAmount: formatAmount(discountAmt),
      };
    });
  };

  const handleServicecategory = async (option, index) => {
    const userId = selectedCustomer.uuid;
    const id = option.value;
    // if (!id || !userId) {
    //     return false;
    //   }
    try {
      const result = await handleEditService(id, userId);
      if (result.data) {
        const newData = result.data?.data;
        const newServiceId = newData.id;
        let newServiceName = '';
        if(selectedCustomer.length > 0){
           newServiceName = selectedCustomer.serviceNames[newServiceId];
        }

        setFormData((prevState) => {
          const updatedServices = [...prevState.services];
          updatedServices[index] = {
            ...updatedServices[index],
            serviceId: newData.id,
            price: newData.price,
            quantity: "1",
            minVal: newData.price,
            // tax:'18',
            total: calculateTotalForRow("1", newData.price, "18"),
          };
          let isServiceDiscountable = false;
          if (selectedCustomer.packageDetails) {
            for (const service of selectedCustomer.packageDetails) {
              if (service.servicesLeft > 0) {
                // Check if servicesLeft is not 0
                const serviceName =
                  selectedCustomer.serviceNames[service.serviceId];
                if (newServiceName && serviceName === newServiceName) {
                  isServiceDiscountable = true;
                  break;
                }
              }
            }
          }

          setshowDiscount(isServiceDiscountable ? "" : "none");
          let subtotal = 0;
          let totalTax = 0;
          let discountAmt = 0;
          const taxEnabled = formData.taxEnabled;

          updatedServices.forEach((service) => {
            subtotal += service.price * service.quantity;
            if (taxEnabled == true) {
              totalTax += (service.price * service.quantity * 18) / 100;
            } else {
              totalTax += (service.price * service.quantity * 18) / 118;
            }
          });

          if (taxEnabled == false) {
            subtotal -= totalTax;
          }

          let grandTotal = subtotal + totalTax;

          const walletAmount = formData.wallet;
          const walletStatus = formData.walletStatus;
          let amountToDeduct;

          if (walletStatus == true && grandTotal != 0) {
            amountToDeduct = Math.min(walletAmount, grandTotal);
            grandTotal -= amountToDeduct;
          }

          // Apply discount if available
          let discount =
            prevState && prevState.discount ? prevState.discount : 0;
          const discountSymbol =
            prevState && prevState.discountSymbol
              ? prevState.discountSymbol
              : "%";

          if (discountSymbol == "%") {
            discountAmt = Math.min(subtotal * (discount / 100), subtotal);
          } else {
            discountAmt = Math.min(discount, subtotal);
          }

          grandTotal -= discountAmt;

          return {
            ...prevState,
            services: updatedServices,
            walletAmount: amountToDeduct,
            subTotal: formatAmount(subtotal),
            billingTax: formatAmount(totalTax),
            grandTotal: formatAmount(grandTotal),
            roundOffGrandTotal: roundOffAmount(grandTotal),
            // taxEnabled:true,
            discount: discount,
            discountedAmount: formatAmount(discountAmt),
          };
        });
      }
    } catch (error) {
      let x = error?.response?.data?.message;
      showToastMessage("error", x ? x : "error");
    }
  };

  const options = [{ value: "", label: "Select Service" }];
  if (services && services.length > 0) {
    const groupedServices = {};
    services.forEach((el) => {
      if (!el.deleted_at) {
        // Check if deleted_at is null or undefined
        if (!groupedServices[el.service_category_id]) {
          groupedServices[el.service_category_id] = [];
        }

        groupedServices[el.service_category_id].push({
          value: el.id,
          label: el.name,
          cat_id: el.get_cat_name?.name,
        });
      }
    });

    for (const category in groupedServices) {
      options.push({
        label: groupedServices[category][0].cat_id,
        options: groupedServices[category],
      });
    }
  }

  const staffOptions = [{ value: "", label: "Select Staff" }];
  if (staffData && staffData.length > 0) {
    staffData.forEach((el) => {
      if (!el.deleted_at && el.role?.role === "employee") {
        // Check if deleted_at is null or undefined and role is 'employee'
        staffOptions.push({
          value: el.id,
          label: el.name,
        });
      }
    });
  }

  const outstanding = formatAmountSymbol(
    Math.abs(selectedCustomer?.out_standing || 0)
  );
  const outstandingRaw = selectedCustomer?.out_standing || 0;
  const formattedOutstanding = formatAmountSymbol(Math.abs(outstandingRaw));

  const handleSubmit = async (actionType) => {
    setTypeBTN(actionType);

    if (validateForm()) {
      setBtn(true);

      try {
        const result = await handleBilling(formData);

        if (result.data) {
          showToastMessage("success", result.data.message);

          if (actionType === "save") {
            navigate("/billing-listing");
            setBtn(false);
            // } else if (actionType === 'savePrint') {
          } else {
            openModal();
            setPdfData(result?.data?.data.serviceBilling);
          }
        }
      } catch (error) {
        setBtn(false);
        if (error.response.status === 404) {
          const errors = error.response.data.message;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
              errors[key].forEach((message) => {
                showToastMessage("error", message);
              });
            });
          }
        } else {
          showToastMessage("error", error.response.data.message);
        }
      }
    }
  };

  const triggerBackspace = (e) => {
    const key = e.keyCode || e.which;

    if (key === 8) {
      setSelectedCustomer([]);
      setShowWallet("none");
    }

    if (e.ctrlKey && key === 88) {
      setSelectedCustomer([]);
      setShowWallet("none");
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center bg-transparent flex-wrap">
          <div className="p-header d-flex flex-column gap-2 align-items-start justify-content-start">
            <h2 className="bodytext-30 fontweight-600 text-dark mb-0">
              Service Billing
            </h2>
          </div>
          {checkPermission(userData, "View service-billings") && (
            <NavLink
              to="/billing-listing"
              className="button button-primary mt-2"
            >
              View All Invoices
            </NavLink>
          )}
        </div>
        <div className="card-body">
          <form id="billingform" autoComplete="off">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="phone">Mobile Number *</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onKeyDown={(e) => {
                          validatePhone(e);
                          triggerBackspace(e);
                        }}
                        onChange={(e) => handleInputChange(e)}
                        onKeyUp={handleKeyUp}
                        onFocus={()=>{setContactInputFocus(true), setNameInputFocus(false)}}
                        className="form-control cust_mob"
                        id="phone"
                        placeholder="Enter Phone Number"
                      />
                     {contactInputFocus && <ul
                        className="typeahead dropdown-menu"
                        style={{ display: showDiv }}
                      >
                        <Listing
                          searchdata={searchdata}
                          handleCustomerClick={(id) => handleCustomerClick(id)}
                        />
                      </ul>}
                      {errors.phone && (
                        <div className="text-danger">{errors.phone}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="Customer">Customer Name *</label>
                      <input
                        type="text"
                        name="name"
                        required=""
                        className="form-control required name"
                        onKeyUp={handleKeyUp}                        value={formData.name}
                        onChange={(e) => handleInputChange(e)}
                        onFocus={()=>{setContactInputFocus(false), setNameInputFocus(true)}}
                        id="name"
                        placeholder="Enter Customer Name"
                      />
                     {nameInputFocus && <ul
                        className="typeahead dropdown-menu"
                        style={{ display: showDiv }}
                      >
                        <Listing
                          searchdata={searchdata}
                          handleCustomerClick={(id) => handleCustomerClick(id)}
                        />
                      </ul>}
                      {errors.name && (
                        <div className="text-danger">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6" style={{ display: showGender }}>
                    <div className="form-group gender_check">
                      <label htmlFor="gender">Gender</label>
                      <select
                        name="gender"
                        id="gender"
                        value={formData.gender}
                        onChange={(e) => handleInputChange(e)}
                        className="cust_gender form-control form-select"
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group gender_check">
                      <label htmlFor="gender">How did you reach us?</label>
                      <select
                        className="form-control form-select"
                        name="refId"
                        value={formData.refId}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="">Select Reference</option>
                       
                        <option value="insta">Instagram</option>
                        <option value="fb">Facebook</option>
                        <option value="walkin">Walkin</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="Customer">Date</label>
                      <input
                        className="form-control"
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={(e) => handleInputChange(e)}
                        placeholder="Date"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="Customer">Time</label>
                      <input
                        className="form-control"
                        type="time"
                        name="time"
                        value={formData.time}
                        onChange={(e) => handleInputChange(e)}
                        placeholder="Time"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <blockquote className="blockquote blockquote-primary customer_detail round-10 ">
                  <CustomerDetail selectedCustomer={selectedCustomer} />
                </blockquote>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 margintop-5 marginbottom-15">
                <button
                  type="button"
                  onClick={addRow}
                  id="btn_add_row"
                  className="button button-primary button-icon-left"
                >
                  <i className="fa fa-plus"></i> Add Service
                </button>
              </div>
              <div className="col-md-12 mt-4">
                <div className="table-billing-form" id="item_table">
                  <div className="row">
                    {formData.services.map((value, index) => (
                      <TableListing
                        key={index}
                        index={index}
                        value={value}
                        minVal={value?.minVal}
                        // formData={formData}
                        services={options} // Pass your options here
                        staffData={staffOptions} // Pass your staffOptions here
                        handleServicecategory={handleServicecategory}
                        handleFieldChange={handleFieldChange}
                        removeRow={removeRow}
                        selectedCustomer={selectedCustomer}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-between mt-2">
              <div className="col-lg-12 col-md-12" id="button_add">
                <div className="form-group">
                  <label for="billing_remark">Billing Remark</label>
                  <textarea
                    className="form-control billing_remark"
                    id="billing_remark"
                    name="billingRemark"
                    value={formData.billingRemark}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 mt-4" id="package">
                <table className="table table-bordered table-billing billing-data">
                  <tbody>
                    <tr>
                      <th style={{ width: "50%" }}>Sub Total</th>
                      <td className="text-right">
                        <span id="subTotal">{formData?.subTotal || 0.0}</span>
                      </td>
                    </tr>

                    <tr>
                      <th>Tax (18%)</th>
                      <td className="text-right">
                        <span id="taxTotal">{formData?.billingTax || 0.0}</span>
                      </td>
                    </tr>

                    <tr className="amount_due tax-include">
                      <th>Tax Exclude/Include</th>
                      <td className="text-right">
                        <div className="form-group mb-0 d-flex justify-content-end">
                          <div className="d-flex justify-content-between align-items-center">
                            <input
                              type="checkbox"
                              className="form-check m-0"
                              name="taxEnabled"
                              checked={formData.taxEnabled}
                              onChange={(e) => {
                                handleInputChange(e);
                                handleFieldChange("taxEnabled", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    {showDiscount && (
                      <tr>
                        <th
                          className="paddingbottom-25"
                          style={{ verticalAlign: "middle" }}
                        >
                          <div className="d-flex align-items-center">
                            <label className="pe-1">Discount</label>
                          </div>
                        </th>
                        <td className="text-right position-relative paddingbottom-25">
                          <div className="discount-wrap">
                            <div className="discount-wrap-field">
                              <select
                                className="text-right input-xs discount_mode form-select form-control"
                                id="discount_mode"
                                value={formData.discountSymbol}
                                onChange={(e) => {
                                  handleInputChange(e);
                                  handleFieldChange(
                                    "discountSymbol",
                                    e.target.value
                                  );
                                }}
                                name="discountSymbol"
                              >
                                <option value="%">%</option>
                                <option value="₹">₹</option>
                              </select>
                            </div>
                            <input
                              className="text-right discount input-sm form-control"
                              id="discount"
                              value={formData.discount}
                              name="discount"
                              type="text"
                              onKeyDown={discountValidate}
                              onChange={(e) => {
                                handleInputChange(e);
                                handleFieldChange("discount", e.target.value);
                              }}
                            />
                          </div>
                          <div
                            id="discount_value"
                            className="text-start discount-amount"
                          >
                            {formData?.discountedAmount > "0.00"
                              ? formData?.discountedAmount
                              : ""}
                          </div>
                        </td>
                      </tr>
                    )}

                    {showSpecialDiscount && (
                      <tr className="amount_due">
                        <th>Special Discount:</th>
                        <td className="text-right">
                          <div className="form-group mb-0">
                            <div className="d-flex justify-content-between align-items-center">
                              <input
                                className="text-right discount input-sm form-control"
                                step="any"
                                min="0"
                                max="100"
                                value={formData?.specialDiscount}
                                name="specialDiscount"
                                type="text"
                                onKeyDown={discountValidate}
                                onChange={(e) => {
                                  handleInputChange(e);
                                }}
                              />
                            </div>
                            {errors.specialDiscount && (
                              <div className="text-danger">
                                {errors.specialDiscount}
                              </div>
                            )}
                          </div>
                          <p className="special-discount-text">
                            *You can apply a maximum discount of {discountLimit}
                            {formData.discountSymbol}. For discounts exceeding
                            this limit, please submit a request to the admin.
                          </p>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <th>Payment Mode</th>
                      <td className="text-right">
                        {fields.map((field, index) => (
                          <>
                            <select
                              key={index}
                              className="form-select form-control"
                              name="paymentMode"
                              value={field.selectValue}
                              onChange={(event) =>{
                                handleSelectChange(event, index);
                                handleInputChange(event);
                             }}
                            >
                              <option value="">Select Payment Mode</option>
                              {selectOptions(null, index)}
                            </select>
                            {isVisible[index] === true && field.selectValue!=="" && (
                              <input
                                min="1"
                                type="number"
                                onChange={(e) =>
                                 { 
                                  handleMultiInputChange(e, index)
                                  errors.paymentMode = ""
                                 }
                                }
                              />
                            )}
                          </>
                        ))}
                        {errors.paymentMode && (
                          <div className="text-danger">
                            {errors.paymentMode}
                          </div>
                        )}
                        {/* {errors.paymentAmountMode && (
                          <div className="text-danger">
                            {errors.paymentAmountMode}
                          </div>
                        )} */}
                        <button
                          type="button"
                          onClick={() =>
                            setFields([
                              ...fields,
                              { selectValue: "", inputValue: "" },
                            ])
                          }
                          id="btn_add_row"
                          className="button button-primary button-icon-left"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </td>
                     
                    </tr>

                    <tr className="amount_due" style={{ display: showWallet }}>
                      <th>Use Wallet Amount:</th>
                      <td className="text-right">
                        <div className="form-group mb-0">
                          <div className="d-flex justify-content-between align-items-center">
                            <input
                              type="checkbox"
                              className="form-check m-0"
                              name="walletStatus"
                              checked={formData.walletStatus}
                              onChange={(e) => {
                                handleInputChange(e);
                                handleFieldChange(
                                  "walletStatus",
                                  e.target.value
                                );
                              }}
                            />
                            <span
                              className="currencySymbol"
                              style={{ display: "inline-block" }}
                            ></span>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="amount_due">
                      <th>Grand Total:</th>
                      <td className="text-right">
                        <span
                          className="currencySymbol"
                          style={{ display: "inline-block" }}
                        ></span>
                        <span id="grandTotal">
                          {formatAmount(formData?.roundOffGrandTotal)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="flex flex-wrap gap-2">
                  <button
                    type="button"
                    onClick={() => handleSubmit("save")}
                    disabled={btn}
                    className="button button-primary table-billing-btn"
                  >
                    Save Bill
                  </button>
                  {outstandingRaw === 0 && (
                    <>
                      <button
                        type="button"
                        onClick={() => handleSubmit("savePrint")}
                        disabled={btn}
                        className="button button-primary table-billing-btn"
                      >
                        Save & Print
                      </button>

                      <button
                        type="button"
                        onClick={() => handleSubmit("savePrintJob")}
                        disabled={btn}
                        className="button button-primary table-billing-btn"
                      >
                        Save & Print Job Card
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
          <IframeModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            closeModal={closeModal}
            customStyles={customStyles}
            //  layout={<BillingInvoice data={pdfData} />}
            layout={
              typeBTN === "savePrint" ? (
                <BillingInvoice data={pdfData} />
              ) : (
                <ClientJobCard data={pdfData} />
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default Billing;
