import styles from "./styles";
import { paymentOption } from "../../helpers/paymentOption";

const BillingInvoice = ({data}) => {

    return (
        <>
      <div className="invoice"  style={styles.invoice}>
      <table style={styles.width}>
        <tr>
          <td>
            <table style={styles.width}>
              <tr>
                <td style={styles.textCenter}> <h1 className="heading" style={styles.heading}>Tress Lounge</h1></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td className="borderb"  style={{ ...styles.borderb, ...styles.paddingb }}>
            <table style={styles.width}>
              <tr>
                <td className="info" style={styles.info}>
                  {(data.date || data.time) && (
                  <p className="info" style={styles.info}>Date: {data.date || ''} {data.time || ''}</p>
                  )}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td className="borderb" style={{ ...styles.paddingt, ...styles.paddingb }}>
            <table style={styles.width}>
              <tr>
                <td>
                  <table style={styles.width}>
                    <tr>
                      <td>
                          <p className="billno" style={styles.billno}>Bill To:</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                          <p className="billno" style={styles.billno}>{data.get_customer_info?.name}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                          <p className="billno" style={styles.billno}>{data.get_customer_info?.phone_number}</p>
                      </td>
                    </tr>
                  </table>
                </td>
                 <td className="info" style={styles.info}>
                    <table>
                      <tr>
                        <td>
                          <p className="billno" style={styles.billno}>Bill No: {data.bill_no}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {data.get_org_info?.gst_no && data.get_org_info?.legal_name && (
                            <div className="billno" style={styles.billno}>
                              <div className="billno" style={styles.billno}>
                                <span className="billno" style={styles.billno}>GST No: {data.get_org_info.gst_no}</span>
                              </div>
                              <div className="billno" style={styles.billno}>
                                <span className="billno" style={styles.billno}>Legal Name: {data.get_org_info.legal_name}1</span>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table className="invoice-table" style={styles.width}>
                <thead className="theadbg"style={styles.theadbg}>
                  <tr>
                    <th className="theadth"style={styles.theadth}>Service</th>
                    <th className="theadth"style={{ ...styles.theadth, ...styles.textCenter }}>Qty</th>
                    <th className="theadth"style={{ ...styles.theadth, ...styles.textRight }}>Price</th>
                    <th className="theadth"style={{ ...styles.theadth, ...styles.textRight }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.get_multiple_services?.map((item, index) => (
                    <tr key={index} className="" style={styles.borderb}>
                      <td className="theadtd" style={styles.theadtd}>{item?.get_service_name?.name || ''}</td>
                      <td className="theadtd"style={{ ...styles.theadtd, ...styles.textCenter }}>{item.quantity}</td>
                      <td className="theadtd"style={{ ...styles.theadtd, ...styles.textRight }}>{item.price}</td>
                      <td className="theadtd"style={{ ...styles.theadtd, ...styles.textRight }}>{item.total}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="1" className="theadtd" style={styles.theadtd}>Subtotal</td>
                    <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.sub_total}</td>
                  </tr>
                  <tr>
                    <td colSpan="1" className="theadtd" style={styles.theadtd}>Tax (18%)</td>
                    <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.tax}</td>
                  </tr>
                  <tr>
                    <td colSpan="1" className="theadtd" style={styles.theadtd}>Discount ({data.discount} {data.discount_symbol})</td>
                    <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.discount_amount}</td>
                  </tr>
                  {data.multiple_payment_types.length > 0 ? (
                    data.multiple_payment_types.map((payment, index) => (
                      <tr key={index}>
                        <td colSpan="1" className="theadtd" style={styles.theadtd}>{paymentOption(payment.payment_mode)}</td>
                        <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>
                          {payment.price}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="1" className="theadtd" style={styles.theadtd}>Payment Mode</td>
                      <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>
                        {data.payment_mode ? paymentOption(data.payment_mode) : data.payment_method}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="1" className="theadtd" style={styles.theadtd}>Total</td>
                    <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.round_off_total}</td>
                  </tr>
                  
                </tfoot>
              </table>
          </td>
        </tr>
      </table>
      </div>
{/* 
    <div className="invoice"  style={styles.invoice}>
      <table style={styles.width}>
        <tr>
          <td>
            <table style={styles.width}>
              <tr>
                <td style={styles.textCenter}> <h1 className="heading" style={styles.heading}>Tress Lounge</h1></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td className="borderb" style={{ ...styles.paddingt, ...styles.paddingb }}>
            <table style={styles.width}>
              <tr>
                <td>
                  <p className="billno" style={styles.billno}>Employee:</p>
                </td>
              </tr>
            </table>
            <table style={styles.width}>
              <tr>
                <td style={{width: '50%'}}>
                  <p className="billno" style={styles.billno}>In time:</p>
                </td>
                <td style={{width: '50%'}}>
                  <p className="billno" style={styles.billno}>Out time:</p>
                </td>
              </tr>
            </table>
            <table style={styles.width}>
              <tr>
                <td style={{width: '60%'}}>
                  <p className="billno" style={styles.billno}>Desgination</p>
                </td>
                <td style={{width: '40%'}}>
                  <p className="billno" style={styles.billno}>Date:</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table className="invoice-table" style={styles.width}>
                <thead className="theadbg"style={styles.theadbg}>
                  <tr>
                    <th className="theadth"style={{width: "33.33%", ...styles.theadth}}>Service</th>
                    <th className="theadth"style={{ width: "33.33%", ...styles.theadth, ...styles.textLeft }}>Upgrade</th>
                    <th className="theadth"style={{ width: "33.33%", ...styles.theadth, ...styles.textLeft }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                    <tr className="" style={styles.borderb}>
                      <td className="theadtd" style={{width: "33.33%", ...styles.theadtd}}></td>
                      <td className="theadtd"style={{ width: "33.33%", ...styles.theadtd, ...styles.textLeft }}></td>
                      <td className="theadtd"style={{ width: "33.33%", ...styles.theadtd, ...styles.textLeft }}></td>
                    </tr>
                </tbody>
              </table>
              <table style={styles.width}>
                <tr>
                  <td style={{width: '50%',textAlign: 'left', ...styles.theadth}}>
                    <p>Total Sales</p>
                  </td>
                  <td style={styles.theadth}></td>
                </tr>
              </table>
          </td>
        </tr>
      </table>
      </div>
*/}
{/*
      <div className="invoice"  style={styles.invoice}>
      <table style={styles.width}>
        <tr>
          <td>
            <table style={styles.width}>
              <tr>
                <td style={styles.textCenter}> <h1 className="heading" style={styles.heading}>Tress Lounge</h1></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td className="borderb" style={{ ...styles.paddingt, ...styles.paddingb }}>
            <table style={styles.width}>
              <tr>
                <td>
                  <p className="billno" style={styles.billno}>Client Name:</p>
                </td>
              </tr>
            </table>
            <table style={styles.width}>
              <tr>
                <td style={{width: '60%'}}>
                  <p className="billno" style={styles.billno}>Ph No:</p>
                </td>
                <td style={{width: '40%'}}>
                  <p className="billno" style={styles.billno}>Date:</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table className="invoice-table" style={styles.width}>
                <thead className="theadbg"style={styles.theadbg}>
                  <tr>
                    <th className="theadth"style={{width: "50%", ...styles.theadth, ...styles.textLeft}}>Service</th>
                    <th className="theadth"style={{ width: "50%", ...styles.theadth, ...styles.textLeft }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                    <tr className="" style={styles.borderb}>
                      <td className="theadtd" style={{width: "50%", height: "25px", ...styles.theadtd, ...styles.textLeft }}></td>
                      <td className="theadtd"style={{ width: "50%", height: "25px", ...styles.theadtd, ...styles.textLeft }}></td>
                    </tr>
                </tbody>
              </table>
              <table style={styles.width}>
                <tr>
                  <td style={{...styles.theadth}}>
                    <p>I am fully setisfied with the service offered to me and have no complaint what so ever <span>Tress Longe</span></p>
                  </td>
                  <td style={styles.theadth}></td>
                </tr>
              </table>
              <table style={styles.width}>
                <tr>
                  <td style={{width: '50%',textAlign: 'left', ...styles.theadth}}>
                    <p>Client Sign</p>
                  </td>
                  <td style={styles.theadth}></td>
                </tr>
              </table>
          </td>
        </tr>
      </table>
      </div>
     
*/}



        
        </>
    )
   

}

export default BillingInvoice;