import React, { useEffect, useState } from 'react';
import Select from 'react-select';
// // import { showToastMessage } from '../../helpers/toastr';
// // import { handleEditService } from '../../services/service';
// // import { formatAmount } from '../../helpers/formatAmount';
import { showToastMessage } from '../../helpers/toastr';

export const TableListing = ({ minVal, index, services, staffData, removeRow, handleServicecategory, value, handleFieldChange, selectedCustomer }) => {
    const [isCoStaffFocused, setIsCoStaffFocused] = useState(false);
    const handleStaffChange = (newStaffId) => {
        let updatedCoStaff = [...value.coStaffId];

        if (updatedCoStaff.includes(newStaffId)) {
            updatedCoStaff = updatedCoStaff.filter(coStaffId => coStaffId !== newStaffId);
        }

        handleFieldChange('staffId', newStaffId, index);
        handleFieldChange('coStaffId', updatedCoStaff, index);
    };

    const handleQuantityChange = (e) => {
        const newQuantity = parseInt(e.target.value, 10); // Parse the value to integer

        if (selectedCustomer && selectedCustomer.packageDetails) {
            const selectedServiceId = value.serviceId; // Assuming value.serviceId is set correctly
            const selectedPackage = selectedCustomer.packageDetails.find(pkg => pkg.serviceId === selectedServiceId);

            if (selectedPackage && selectedPackage.servicesLeft !== null && selectedPackage.servicesLeft > 0) {
                if (newQuantity > selectedPackage.servicesLeft) {
                    showToastMessage('error', `Quantity cannot exceed the services left: ${selectedPackage.servicesLeft}`);
                } else {
                    handleFieldChange('quantity', newQuantity, index);
                }
            } else {
                handleFieldChange('quantity', newQuantity, index);
            }
        }
    };
    return (
        <div className="col-md-12" id={index} key={index}>
            <div className="added-card">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-12">
                        <div className="form-group">
                            <label>Service</label>
                            <Select
                                name="serviceId"
                                options={services}
                                placeholder={'Select Service'}
                                isSearchable={true}
                                classNamePrefix="form"
                                // value={services.find(option => option.value === value.serviceId)}
                                value={services.reduce((acc, group) => {
                                    if (group && group.options) {
                                        const selectedOption = group.options.find(option => option.value === value.serviceId);
                                        if (selectedOption) {
                                            acc = selectedOption;
                                        }
                                    }
                                    return acc;
                                }, null)}
                                onChange={(option) => {
                                    handleServicecategory(option, index);
                                    handleFieldChange('serviceId', option.value, index)
                                }}
                            />
                            {/* {errors.name && (
                            <div className="text-danger">{errors.name}</div>
                            )} */}
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-4 col-12">
                        <div className="form-group">
                            <label>Staff</label>
                            <Select
                                name="staffId"
                                value={staffData.find(option => option.value === value.staffId)}
                                isSearchable={true}
                                classNamePrefix="form"
                                options={staffData}
                                placeholder={'Select Staff'}
                                onChange={option => handleStaffChange(option.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-12">
                        <div className="form-group">
                            <label>Co-Staff</label>
                            <Select

                                value={
                                    staffData.filter(
                                        option =>
                                            value?.coStaffId.includes(option.value)
                                    )
                                }
                                name="coStaffId"
                                isSearchable={true}
                                options={
                                    staffData.filter(option => option.value !== value.staffId) // Exclude selected staff from co-staff options
                                }
                                isMulti={true}
                                classNamePrefix="form"
                                placeholder={'Select Co-Staff'}
                                onChange={selectedOptions => {
                                    if (!value.staffId) {
                                        setIsCoStaffFocused(true);
                                        return;
                                    }
                                    handleFieldChange('coStaffId', selectedOptions.map(option => option.value), index);
                                }}
                                onFocus={() => setIsCoStaffFocused(true)}
                            />
                            {isCoStaffFocused && !value.staffId && (
                                <small className="text-danger">Please select a staff member first.</small>
                            )}
                            {/* <Select
                    value={staffData.filter(option => value.coStaffId.includes(option.value))}
                    name="coStaffId"
                    isSearchable={true}
                    options={staffData}
                    isMulti={true}
                    placeholder={'Select Co-Staff'}
                    onChange={selectedOptions => {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            coStaffId: selectedOptions.map(option => option.value)
                        }));
                    }}
                /> */}


                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-12">
                        <div className="form-group">
                            <label>Quantity</label>
                            <input
                                name="quantity"
                                type="number"
                                value={value.quantity}
                                className="required form-control calcEvent service_qty input-sm"
                                step="any"
                                min="1"
                                onChange={handleQuantityChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-12">
                        <div className="form-group">
                            <label>Price</label>
                            <input
                                name="price"
                                type="number"
                                value={value.price}
                                className="required form-control calcEvent service_price input-sm"
                                step="any"
                                min={minVal}
                                onChange={e => {
                                    const newValue = parseFloat(e.target.value);
                                    if (newValue >= minVal || e.target.value === '') {
                                        handleFieldChange('price', e.target.value, index);
                                    } else {
                                        const parsedValue = parseFloat(newValue);
                                        if (!isNaN(parsedValue) && parsedValue >= minVal) {
                                            handleFieldChange('price', newValue, index);
                                        } else {
                                            handleFieldChange('price', minVal, index);
                                        }
                                    }
                                }}
                                onBlur={e => {
                                    // Ensure that the field does not get left empty after focus leaves
                                    if (e.target.value === '') {
                                        handleFieldChange('price', minVal, index);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {/* <td className='td-xs'>
            <div className="percentage">
                <input
                    name="tax"
                    type="hidden"
                    value={value.tax || 18}
                    onChange={e => handleFieldChange('tax', e.target.value, index)}
                    className="service_gst form-control"
                    placeholder="GST(%)"
                />
                <span className="service_gst_txt">18%</span>
            </div>
        </td> */}
                    <div className="col-lg-1 col-md-4 col-6">
                        <div className="form-group">
                            <label>Total</label>
                            <div className="percentage margintop-12">
                                <input
                                    name="total"
                                    type="hidden"
                                    value={value.total}
                                    className="service_total form-control"
                                />
                                <span className="service_total_txt">{value.total || '0.00'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-4 col-6 col-delete">
                        <div className="form-group">

                            <button type="button" className="button button-primary button-icon" onClick={() => removeRow(index)}>
                                <i class="mdi mdi-delete-empty menu-icon bodytext-24"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
